exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-page-components-fr-ca-resources-js": () => import("./../../../src/components/PageComponents/fr-ca/resources.js" /* webpackChunkName: "component---src-components-page-components-fr-ca-resources-js" */),
  "component---src-components-page-components-resource-ssr-js": () => import("./../../../src/components/PageComponents/resource-ssr.js" /* webpackChunkName: "component---src-components-page-components-resource-ssr-js" */),
  "component---src-components-page-components-resources-js": () => import("./../../../src/components/PageComponents/resources.js" /* webpackChunkName: "component---src-components-page-components-resources-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-au-about-js": () => import("./../../../src/pages/au/about.js" /* webpackChunkName: "component---src-pages-au-about-js" */),
  "component---src-pages-au-contact-us-js": () => import("./../../../src/pages/au/contact-us.js" /* webpackChunkName: "component---src-pages-au-contact-us-js" */),
  "component---src-pages-au-download-ebook-js": () => import("./../../../src/pages/au/download-ebook.js" /* webpackChunkName: "component---src-pages-au-download-ebook-js" */),
  "component---src-pages-au-dr-edwards-js": () => import("./../../../src/pages/au/dr-edwards.js" /* webpackChunkName: "component---src-pages-au-dr-edwards-js" */),
  "component---src-pages-au-dr-naidoo-js": () => import("./../../../src/pages/au/dr-naidoo.js" /* webpackChunkName: "component---src-pages-au-dr-naidoo-js" */),
  "component---src-pages-au-e-dinfopack-js": () => import("./../../../src/pages/au/EDinfopack.js" /* webpackChunkName: "component---src-pages-au-e-dinfopack-js" */),
  "component---src-pages-au-explainer-js": () => import("./../../../src/pages/au/explainer.js" /* webpackChunkName: "component---src-pages-au-explainer-js" */),
  "component---src-pages-au-index-js": () => import("./../../../src/pages/au/index.js" /* webpackChunkName: "component---src-pages-au-index-js" */),
  "component---src-pages-au-p-einfopack-js": () => import("./../../../src/pages/au/PEinfopack.js" /* webpackChunkName: "component---src-pages-au-p-einfopack-js" */),
  "component---src-pages-au-privacy-policy-js": () => import("./../../../src/pages/au/privacy-policy.js" /* webpackChunkName: "component---src-pages-au-privacy-policy-js" */),
  "component---src-pages-au-quiz-js": () => import("./../../../src/pages/au/quiz.js" /* webpackChunkName: "component---src-pages-au-quiz-js" */),
  "component---src-pages-au-resource-js": () => import("./../../../src/pages/au/resource/[...].js" /* webpackChunkName: "component---src-pages-au-resource-js" */),
  "component---src-pages-au-resources-author-dr-fred-edwards-js": () => import("./../../../src/pages/au/resources/author/dr-fred-edwards.js" /* webpackChunkName: "component---src-pages-au-resources-author-dr-fred-edwards-js" */),
  "component---src-pages-au-resources-author-dr-suren-naidoo-js": () => import("./../../../src/pages/au/resources/author/dr-suren-naidoo.js" /* webpackChunkName: "component---src-pages-au-resources-author-dr-suren-naidoo-js" */),
  "component---src-pages-au-resources-author-dr-thomas-stock-js": () => import("./../../../src/pages/au/resources/author/dr-thomas-stock.js" /* webpackChunkName: "component---src-pages-au-resources-author-dr-thomas-stock-js" */),
  "component---src-pages-au-resources-authors-js": () => import("./../../../src/pages/au/resources/authors.js" /* webpackChunkName: "component---src-pages-au-resources-authors-js" */),
  "component---src-pages-au-terms-conditions-js": () => import("./../../../src/pages/au/terms-conditions.js" /* webpackChunkName: "component---src-pages-au-terms-conditions-js" */),
  "component---src-pages-au-thank-you-js": () => import("./../../../src/pages/au/thank-you.js" /* webpackChunkName: "component---src-pages-au-thank-you-js" */),
  "component---src-pages-au-thank-you-pdf-js": () => import("./../../../src/pages/au/thank-you-pdf.js" /* webpackChunkName: "component---src-pages-au-thank-you-pdf-js" */),
  "component---src-pages-au-thank-you-td-js": () => import("./../../../src/pages/au/thank-you-td.js" /* webpackChunkName: "component---src-pages-au-thank-you-td-js" */),
  "component---src-pages-au-treatment-cost-js": () => import("./../../../src/pages/au/treatment-cost.js" /* webpackChunkName: "component---src-pages-au-treatment-cost-js" */),
  "component---src-pages-au-treatments-chronic-pain-js": () => import("./../../../src/pages/au/treatments/chronic-pain.js" /* webpackChunkName: "component---src-pages-au-treatments-chronic-pain-js" */),
  "component---src-pages-au-treatments-erectile-dysfunction-js": () => import("./../../../src/pages/au/treatments/erectile-dysfunction.js" /* webpackChunkName: "component---src-pages-au-treatments-erectile-dysfunction-js" */),
  "component---src-pages-au-treatments-index-js": () => import("./../../../src/pages/au/treatments/index.js" /* webpackChunkName: "component---src-pages-au-treatments-index-js" */),
  "component---src-pages-au-treatments-premature-ejaculation-js": () => import("./../../../src/pages/au/treatments/premature-ejaculation.js" /* webpackChunkName: "component---src-pages-au-treatments-premature-ejaculation-js" */),
  "component---src-pages-au-treatments-testosterone-replacement-therapy-js": () => import("./../../../src/pages/au/treatments/testosterone-replacement-therapy.js" /* webpackChunkName: "component---src-pages-au-treatments-testosterone-replacement-therapy-js" */),
  "component---src-pages-au-welcomevideo-js": () => import("./../../../src/pages/au/welcomevideo.js" /* webpackChunkName: "component---src-pages-au-welcomevideo-js" */),
  "component---src-pages-booknow-js": () => import("./../../../src/pages/booknow.js" /* webpackChunkName: "component---src-pages-booknow-js" */),
  "component---src-pages-ca-2-minute-quiz-js": () => import("./../../../src/pages/ca/2-minute-quiz.js" /* webpackChunkName: "component---src-pages-ca-2-minute-quiz-js" */),
  "component---src-pages-ca-about-js": () => import("./../../../src/pages/ca/about.js" /* webpackChunkName: "component---src-pages-ca-about-js" */),
  "component---src-pages-ca-contact-us-js": () => import("./../../../src/pages/ca/contact-us.js" /* webpackChunkName: "component---src-pages-ca-contact-us-js" */),
  "component---src-pages-ca-download-ebook-js": () => import("./../../../src/pages/ca/download-ebook.js" /* webpackChunkName: "component---src-pages-ca-download-ebook-js" */),
  "component---src-pages-ca-dr-edwards-js": () => import("./../../../src/pages/ca/dr-edwards.js" /* webpackChunkName: "component---src-pages-ca-dr-edwards-js" */),
  "component---src-pages-ca-dr-naidoo-js": () => import("./../../../src/pages/ca/dr-naidoo.js" /* webpackChunkName: "component---src-pages-ca-dr-naidoo-js" */),
  "component---src-pages-ca-e-dinfopack-js": () => import("./../../../src/pages/ca/EDinfopack.js" /* webpackChunkName: "component---src-pages-ca-e-dinfopack-js" */),
  "component---src-pages-ca-erectile-dysfunction-2-minute-quiz-js": () => import("./../../../src/pages/ca/erectile-dysfunction/2-minute-quiz.js" /* webpackChunkName: "component---src-pages-ca-erectile-dysfunction-2-minute-quiz-js" */),
  "component---src-pages-ca-index-js": () => import("./../../../src/pages/ca/index.js" /* webpackChunkName: "component---src-pages-ca-index-js" */),
  "component---src-pages-ca-p-einfopack-js": () => import("./../../../src/pages/ca/PEinfopack.js" /* webpackChunkName: "component---src-pages-ca-p-einfopack-js" */),
  "component---src-pages-ca-premature-ejaculation-2-minute-quiz-js": () => import("./../../../src/pages/ca/premature-ejaculation/2-minute-quiz.js" /* webpackChunkName: "component---src-pages-ca-premature-ejaculation-2-minute-quiz-js" */),
  "component---src-pages-ca-privacy-policy-js": () => import("./../../../src/pages/ca/privacy-policy.js" /* webpackChunkName: "component---src-pages-ca-privacy-policy-js" */),
  "component---src-pages-ca-quiz-js": () => import("./../../../src/pages/ca/quiz.js" /* webpackChunkName: "component---src-pages-ca-quiz-js" */),
  "component---src-pages-ca-resource-js": () => import("./../../../src/pages/ca/resource/[...].js" /* webpackChunkName: "component---src-pages-ca-resource-js" */),
  "component---src-pages-ca-resources-author-dr-fred-edwards-js": () => import("./../../../src/pages/ca/resources/author/dr-fred-edwards.js" /* webpackChunkName: "component---src-pages-ca-resources-author-dr-fred-edwards-js" */),
  "component---src-pages-ca-resources-author-dr-suren-naidoo-js": () => import("./../../../src/pages/ca/resources/author/dr-suren-naidoo.js" /* webpackChunkName: "component---src-pages-ca-resources-author-dr-suren-naidoo-js" */),
  "component---src-pages-ca-resources-author-dr-thomas-stock-js": () => import("./../../../src/pages/ca/resources/author/dr-thomas-stock.js" /* webpackChunkName: "component---src-pages-ca-resources-author-dr-thomas-stock-js" */),
  "component---src-pages-ca-resources-authors-js": () => import("./../../../src/pages/ca/resources/authors.js" /* webpackChunkName: "component---src-pages-ca-resources-authors-js" */),
  "component---src-pages-ca-terms-conditions-js": () => import("./../../../src/pages/ca/terms-conditions.js" /* webpackChunkName: "component---src-pages-ca-terms-conditions-js" */),
  "component---src-pages-ca-thank-you-js": () => import("./../../../src/pages/ca/thank-you.js" /* webpackChunkName: "component---src-pages-ca-thank-you-js" */),
  "component---src-pages-ca-thank-you-pdf-js": () => import("./../../../src/pages/ca/thank-you-pdf.js" /* webpackChunkName: "component---src-pages-ca-thank-you-pdf-js" */),
  "component---src-pages-ca-treatment-cost-js": () => import("./../../../src/pages/ca/treatment-cost.js" /* webpackChunkName: "component---src-pages-ca-treatment-cost-js" */),
  "component---src-pages-ca-treatments-erectile-dysfunction-js": () => import("./../../../src/pages/ca/treatments/erectile-dysfunction.js" /* webpackChunkName: "component---src-pages-ca-treatments-erectile-dysfunction-js" */),
  "component---src-pages-ca-treatments-index-js": () => import("./../../../src/pages/ca/treatments/index.js" /* webpackChunkName: "component---src-pages-ca-treatments-index-js" */),
  "component---src-pages-ca-treatments-premature-ejaculation-js": () => import("./../../../src/pages/ca/treatments/premature-ejaculation.js" /* webpackChunkName: "component---src-pages-ca-treatments-premature-ejaculation-js" */),
  "component---src-pages-ca-welcomevideo-js": () => import("./../../../src/pages/ca/welcomevideo.js" /* webpackChunkName: "component---src-pages-ca-welcomevideo-js" */),
  "component---src-pages-dietplan-js": () => import("./../../../src/pages/dietplan.js" /* webpackChunkName: "component---src-pages-dietplan-js" */),
  "component---src-pages-fitnessplan-js": () => import("./../../../src/pages/fitnessplan.js" /* webpackChunkName: "component---src-pages-fitnessplan-js" */),
  "component---src-pages-fr-ca-about-js": () => import("./../../../src/pages/fr-ca/about.js" /* webpackChunkName: "component---src-pages-fr-ca-about-js" */),
  "component---src-pages-fr-ca-contact-us-js": () => import("./../../../src/pages/fr-ca/contact-us.js" /* webpackChunkName: "component---src-pages-fr-ca-contact-us-js" */),
  "component---src-pages-fr-ca-download-ebook-js": () => import("./../../../src/pages/fr-ca/download-ebook.js" /* webpackChunkName: "component---src-pages-fr-ca-download-ebook-js" */),
  "component---src-pages-fr-ca-dr-edwards-js": () => import("./../../../src/pages/fr-ca/dr-edwards.js" /* webpackChunkName: "component---src-pages-fr-ca-dr-edwards-js" */),
  "component---src-pages-fr-ca-dr-naidoo-js": () => import("./../../../src/pages/fr-ca/dr-naidoo.js" /* webpackChunkName: "component---src-pages-fr-ca-dr-naidoo-js" */),
  "component---src-pages-fr-ca-e-dinfopack-js": () => import("./../../../src/pages/fr-ca/EDinfopack.js" /* webpackChunkName: "component---src-pages-fr-ca-e-dinfopack-js" */),
  "component---src-pages-fr-ca-index-js": () => import("./../../../src/pages/fr-ca/index.js" /* webpackChunkName: "component---src-pages-fr-ca-index-js" */),
  "component---src-pages-fr-ca-p-einfopack-js": () => import("./../../../src/pages/fr-ca/PEinfopack.js" /* webpackChunkName: "component---src-pages-fr-ca-p-einfopack-js" */),
  "component---src-pages-fr-ca-privacy-policy-js": () => import("./../../../src/pages/fr-ca/privacy-policy.js" /* webpackChunkName: "component---src-pages-fr-ca-privacy-policy-js" */),
  "component---src-pages-fr-ca-quiz-js": () => import("./../../../src/pages/fr-ca/quiz.js" /* webpackChunkName: "component---src-pages-fr-ca-quiz-js" */),
  "component---src-pages-fr-ca-resource-js": () => import("./../../../src/pages/fr-ca/resource/[...].js" /* webpackChunkName: "component---src-pages-fr-ca-resource-js" */),
  "component---src-pages-fr-ca-resources-author-dr-fred-edwards-js": () => import("./../../../src/pages/fr-ca/resources/author/dr-fred-edwards.js" /* webpackChunkName: "component---src-pages-fr-ca-resources-author-dr-fred-edwards-js" */),
  "component---src-pages-fr-ca-resources-author-dr-suren-naidoo-js": () => import("./../../../src/pages/fr-ca/resources/author/dr-suren-naidoo.js" /* webpackChunkName: "component---src-pages-fr-ca-resources-author-dr-suren-naidoo-js" */),
  "component---src-pages-fr-ca-resources-author-dr-thomas-stock-js": () => import("./../../../src/pages/fr-ca/resources/author/dr-thomas-stock.js" /* webpackChunkName: "component---src-pages-fr-ca-resources-author-dr-thomas-stock-js" */),
  "component---src-pages-fr-ca-resources-authors-js": () => import("./../../../src/pages/fr-ca/resources/authors.js" /* webpackChunkName: "component---src-pages-fr-ca-resources-authors-js" */),
  "component---src-pages-fr-ca-terms-conditions-js": () => import("./../../../src/pages/fr-ca/terms-conditions.js" /* webpackChunkName: "component---src-pages-fr-ca-terms-conditions-js" */),
  "component---src-pages-fr-ca-thank-you-js": () => import("./../../../src/pages/fr-ca/thank-you.js" /* webpackChunkName: "component---src-pages-fr-ca-thank-you-js" */),
  "component---src-pages-fr-ca-thank-you-pdf-js": () => import("./../../../src/pages/fr-ca/thank-you-pdf.js" /* webpackChunkName: "component---src-pages-fr-ca-thank-you-pdf-js" */),
  "component---src-pages-fr-ca-treatment-cost-js": () => import("./../../../src/pages/fr-ca/treatment-cost.js" /* webpackChunkName: "component---src-pages-fr-ca-treatment-cost-js" */),
  "component---src-pages-fr-ca-treatments-erectile-dysfunction-js": () => import("./../../../src/pages/fr-ca/treatments/erectile-dysfunction.js" /* webpackChunkName: "component---src-pages-fr-ca-treatments-erectile-dysfunction-js" */),
  "component---src-pages-fr-ca-treatments-index-js": () => import("./../../../src/pages/fr-ca/treatments/index.js" /* webpackChunkName: "component---src-pages-fr-ca-treatments-index-js" */),
  "component---src-pages-fr-ca-treatments-premature-ejaculation-js": () => import("./../../../src/pages/fr-ca/treatments/premature-ejaculation.js" /* webpackChunkName: "component---src-pages-fr-ca-treatments-premature-ejaculation-js" */),
  "component---src-pages-fr-ca-welcomevideo-js": () => import("./../../../src/pages/fr-ca/welcomevideo.js" /* webpackChunkName: "component---src-pages-fr-ca-welcomevideo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-livechat-js": () => import("./../../../src/pages/lp/livechat.js" /* webpackChunkName: "component---src-pages-lp-livechat-js" */),
  "component---src-pages-nz-about-js": () => import("./../../../src/pages/nz/about.js" /* webpackChunkName: "component---src-pages-nz-about-js" */),
  "component---src-pages-nz-complaints-policy-js": () => import("./../../../src/pages/nz/complaints-policy.js" /* webpackChunkName: "component---src-pages-nz-complaints-policy-js" */),
  "component---src-pages-nz-contact-us-js": () => import("./../../../src/pages/nz/contact-us.js" /* webpackChunkName: "component---src-pages-nz-contact-us-js" */),
  "component---src-pages-nz-download-ebook-js": () => import("./../../../src/pages/nz/download-ebook.js" /* webpackChunkName: "component---src-pages-nz-download-ebook-js" */),
  "component---src-pages-nz-dr-edwards-js": () => import("./../../../src/pages/nz/dr-edwards.js" /* webpackChunkName: "component---src-pages-nz-dr-edwards-js" */),
  "component---src-pages-nz-dr-naidoo-js": () => import("./../../../src/pages/nz/dr-naidoo.js" /* webpackChunkName: "component---src-pages-nz-dr-naidoo-js" */),
  "component---src-pages-nz-e-dinfopack-js": () => import("./../../../src/pages/nz/EDinfopack.js" /* webpackChunkName: "component---src-pages-nz-e-dinfopack-js" */),
  "component---src-pages-nz-index-js": () => import("./../../../src/pages/nz/index.js" /* webpackChunkName: "component---src-pages-nz-index-js" */),
  "component---src-pages-nz-p-einfopack-js": () => import("./../../../src/pages/nz/PEinfopack.js" /* webpackChunkName: "component---src-pages-nz-p-einfopack-js" */),
  "component---src-pages-nz-privacy-policy-js": () => import("./../../../src/pages/nz/privacy-policy.js" /* webpackChunkName: "component---src-pages-nz-privacy-policy-js" */),
  "component---src-pages-nz-quiz-js": () => import("./../../../src/pages/nz/quiz.js" /* webpackChunkName: "component---src-pages-nz-quiz-js" */),
  "component---src-pages-nz-resource-js": () => import("./../../../src/pages/nz/resource/[...].js" /* webpackChunkName: "component---src-pages-nz-resource-js" */),
  "component---src-pages-nz-resources-author-dr-fred-edwards-js": () => import("./../../../src/pages/nz/resources/author/dr-fred-edwards.js" /* webpackChunkName: "component---src-pages-nz-resources-author-dr-fred-edwards-js" */),
  "component---src-pages-nz-resources-author-dr-suren-naidoo-js": () => import("./../../../src/pages/nz/resources/author/dr-suren-naidoo.js" /* webpackChunkName: "component---src-pages-nz-resources-author-dr-suren-naidoo-js" */),
  "component---src-pages-nz-resources-author-dr-thomas-stock-js": () => import("./../../../src/pages/nz/resources/author/dr-thomas-stock.js" /* webpackChunkName: "component---src-pages-nz-resources-author-dr-thomas-stock-js" */),
  "component---src-pages-nz-resources-authors-js": () => import("./../../../src/pages/nz/resources/authors.js" /* webpackChunkName: "component---src-pages-nz-resources-authors-js" */),
  "component---src-pages-nz-terms-conditions-js": () => import("./../../../src/pages/nz/terms-conditions.js" /* webpackChunkName: "component---src-pages-nz-terms-conditions-js" */),
  "component---src-pages-nz-thank-you-js": () => import("./../../../src/pages/nz/thank-you.js" /* webpackChunkName: "component---src-pages-nz-thank-you-js" */),
  "component---src-pages-nz-thank-you-pdf-js": () => import("./../../../src/pages/nz/thank-you-pdf.js" /* webpackChunkName: "component---src-pages-nz-thank-you-pdf-js" */),
  "component---src-pages-nz-treatment-cost-js": () => import("./../../../src/pages/nz/treatment-cost.js" /* webpackChunkName: "component---src-pages-nz-treatment-cost-js" */),
  "component---src-pages-nz-treatments-erectile-dysfunction-js": () => import("./../../../src/pages/nz/treatments/erectile-dysfunction.js" /* webpackChunkName: "component---src-pages-nz-treatments-erectile-dysfunction-js" */),
  "component---src-pages-nz-treatments-index-js": () => import("./../../../src/pages/nz/treatments/index.js" /* webpackChunkName: "component---src-pages-nz-treatments-index-js" */),
  "component---src-pages-nz-treatments-premature-ejaculation-js": () => import("./../../../src/pages/nz/treatments/premature-ejaculation.js" /* webpackChunkName: "component---src-pages-nz-treatments-premature-ejaculation-js" */),
  "component---src-pages-nz-treatments-testosterone-replacement-therapy-js": () => import("./../../../src/pages/nz/treatments/testosterone-replacement-therapy.js" /* webpackChunkName: "component---src-pages-nz-treatments-testosterone-replacement-therapy-js" */),
  "component---src-pages-nz-welcomevideo-js": () => import("./../../../src/pages/nz/welcomevideo.js" /* webpackChunkName: "component---src-pages-nz-welcomevideo-js" */),
  "component---src-pages-selfinjectguide-js": () => import("./../../../src/pages/selfinjectguide.js" /* webpackChunkName: "component---src-pages-selfinjectguide-js" */),
  "component---src-pages-uk-about-js": () => import("./../../../src/pages/uk/about.js" /* webpackChunkName: "component---src-pages-uk-about-js" */),
  "component---src-pages-uk-contact-us-js": () => import("./../../../src/pages/uk/contact-us.js" /* webpackChunkName: "component---src-pages-uk-contact-us-js" */),
  "component---src-pages-uk-download-ebook-js": () => import("./../../../src/pages/uk/download-ebook.js" /* webpackChunkName: "component---src-pages-uk-download-ebook-js" */),
  "component---src-pages-uk-dr-edwards-js": () => import("./../../../src/pages/uk/dr-edwards.js" /* webpackChunkName: "component---src-pages-uk-dr-edwards-js" */),
  "component---src-pages-uk-dr-naidoo-js": () => import("./../../../src/pages/uk/dr-naidoo.js" /* webpackChunkName: "component---src-pages-uk-dr-naidoo-js" */),
  "component---src-pages-uk-e-dinfopack-js": () => import("./../../../src/pages/uk/EDinfopack.js" /* webpackChunkName: "component---src-pages-uk-e-dinfopack-js" */),
  "component---src-pages-uk-explainer-js": () => import("./../../../src/pages/uk/explainer.js" /* webpackChunkName: "component---src-pages-uk-explainer-js" */),
  "component---src-pages-uk-index-js": () => import("./../../../src/pages/uk/index.js" /* webpackChunkName: "component---src-pages-uk-index-js" */),
  "component---src-pages-uk-p-einfopack-js": () => import("./../../../src/pages/uk/PEinfopack.js" /* webpackChunkName: "component---src-pages-uk-p-einfopack-js" */),
  "component---src-pages-uk-privacy-policy-js": () => import("./../../../src/pages/uk/privacy-policy.js" /* webpackChunkName: "component---src-pages-uk-privacy-policy-js" */),
  "component---src-pages-uk-quiz-js": () => import("./../../../src/pages/uk/quiz.js" /* webpackChunkName: "component---src-pages-uk-quiz-js" */),
  "component---src-pages-uk-resource-js": () => import("./../../../src/pages/uk/resource/[...].js" /* webpackChunkName: "component---src-pages-uk-resource-js" */),
  "component---src-pages-uk-resources-author-dr-fred-edwards-js": () => import("./../../../src/pages/uk/resources/author/dr-fred-edwards.js" /* webpackChunkName: "component---src-pages-uk-resources-author-dr-fred-edwards-js" */),
  "component---src-pages-uk-resources-author-dr-suren-naidoo-js": () => import("./../../../src/pages/uk/resources/author/dr-suren-naidoo.js" /* webpackChunkName: "component---src-pages-uk-resources-author-dr-suren-naidoo-js" */),
  "component---src-pages-uk-resources-author-dr-thomas-stock-js": () => import("./../../../src/pages/uk/resources/author/dr-thomas-stock.js" /* webpackChunkName: "component---src-pages-uk-resources-author-dr-thomas-stock-js" */),
  "component---src-pages-uk-resources-authors-js": () => import("./../../../src/pages/uk/resources/authors.js" /* webpackChunkName: "component---src-pages-uk-resources-authors-js" */),
  "component---src-pages-uk-terms-conditions-js": () => import("./../../../src/pages/uk/terms-conditions.js" /* webpackChunkName: "component---src-pages-uk-terms-conditions-js" */),
  "component---src-pages-uk-thank-you-js": () => import("./../../../src/pages/uk/thank-you.js" /* webpackChunkName: "component---src-pages-uk-thank-you-js" */),
  "component---src-pages-uk-thank-you-pdf-js": () => import("./../../../src/pages/uk/thank-you-pdf.js" /* webpackChunkName: "component---src-pages-uk-thank-you-pdf-js" */),
  "component---src-pages-uk-treatment-cost-js": () => import("./../../../src/pages/uk/treatment-cost.js" /* webpackChunkName: "component---src-pages-uk-treatment-cost-js" */),
  "component---src-pages-uk-treatments-erectile-dysfunction-js": () => import("./../../../src/pages/uk/treatments/erectile-dysfunction.js" /* webpackChunkName: "component---src-pages-uk-treatments-erectile-dysfunction-js" */),
  "component---src-pages-uk-treatments-index-js": () => import("./../../../src/pages/uk/treatments/index.js" /* webpackChunkName: "component---src-pages-uk-treatments-index-js" */),
  "component---src-pages-uk-treatments-pe-treatment-london-js": () => import("./../../../src/pages/uk/treatments/pe-treatment-london.js" /* webpackChunkName: "component---src-pages-uk-treatments-pe-treatment-london-js" */),
  "component---src-pages-uk-treatments-pe-treatment-scotland-js": () => import("./../../../src/pages/uk/treatments/pe-treatment-scotland.js" /* webpackChunkName: "component---src-pages-uk-treatments-pe-treatment-scotland-js" */),
  "component---src-pages-uk-treatments-premature-ejaculation-js": () => import("./../../../src/pages/uk/treatments/premature-ejaculation.js" /* webpackChunkName: "component---src-pages-uk-treatments-premature-ejaculation-js" */),
  "component---src-pages-uk-welcomevideo-js": () => import("./../../../src/pages/uk/welcomevideo.js" /* webpackChunkName: "component---src-pages-uk-welcomevideo-js" */)
}

